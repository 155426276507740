<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-03-20 10:58:29
 * @LastEditTime: 2023-11-20 16:24:57
 * @Descripttion: 首页【已入驻】
-->
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-left: 120px;

  &::before {
    content: "";
    width: 100%;
    height: 308px;
    padding-top: 2.5vh;
    background: #4e329f;
    border-radius: 0px 0px 60px 0px;
    position: absolute;
    top: -2.5vh;
    left: 0;
  }

  &-inner {
    width: calc(100% - 30px);
    height: 100%;
    margin: 0 0 0 30px;
    overflow: hidden;
    overflow-y: auto;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    @include flexBox;

    .container {
      width: calc(100% - 30px);
      @include flexBox(space-between);
    }

    .no-data {
      background: #fff;
      border-radius: 10px;
      position: relative;

      &--empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 150px;
          margin-bottom: 10px;
        }

        p {
          color: #c1c1c1;
          text-align: center;
        }
      }
    }

    .notice {
      &-box {
        width: calc(100% - 30px);
        height: 80px;
        border-bottom: 1px solid rgba($color: #ede6ff, $alpha: 0.11);
        margin-bottom: 28px;
        @include flexBox;

        .iconfont {
          font-size: 30px;
          color: #ede6ff;
          margin-right: 14px;
        }
        .el-button {
          height: 40px;
        }
      }

      &-wrapper {
        width: calc(100% - 50px);
        height: 40px;

        p {
          max-width: 600px;
          line-height: 40px;
          font-size: 16px;
          color: #f7f7ff;
        }
      }
    }

    .wrapper {
      &-left {
        width: 67.25%;
        height: 812px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .look-back,
        .strict-course {
          width: 100%;
          height: calc((100% - 28px) / 2);
          &--inner {
            height: calc(100% - 72px);
            box-sizing: border-box;
            padding: 0 18px;
            background: #fff;
            border-radius: 0 0 10px 10px;
            position: relative;

            &.no-data {
              border-radius: 0 0 10px 10px;
            }
            .class-info {
              width: 100%;
              height: 100%;
            }
          }
          .no-data--empty {
            img {
              width: 180px;
            }

            p {
              margin-top: 8px;
            }
          }
        }
      }

      &-right {
        width: 32%;
        height: 816px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .homework,
        .notice {
          width: 100%;
          height: calc((100% - 28px) / 2);
          &--inner {
            height: calc(100% - 72px);
            background: #fff;
            border-radius: 0 0 10px 10px;
            box-sizing: border-box;
            padding: 4px 20px;

            &.no-data {
              border-radius: 0 0 10px 10px;
            }
          }

          &--list {
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
              height: 0;
              width: 0;
              color: transparent;
            }
          }

          &-item {
            width: 100%;
            height: 112px;
            box-sizing: border-box;
            padding: 12px 15px;
            border-bottom: 1px dashed rgba($color: #a2a2a2, $alpha: 0.17);
            cursor: pointer;

            &:hover {
              border-radius: 10px;
              background: #e9e9f4;
              border-bottom: 1px solid #e9e9f4;

              .title .line-text--1st {
                color: #6340c8;
              }
            }

            .time {
              height: 24px;
              margin-bottom: 6px;
              color: #949495;
              @include flexBox;

              .iconfont {
                font-size: 18px;
                color: #666;
                margin-right: 8px;
              }
            }

            .title {
              height: 22px;
              @include flexBox;

              .line-text--1st {
                max-width: 100%;
                margin-right: 10px;
                color: #0b0b0b;
                font-size: 16px;
                margin-right: 8px;
              }

              .tag {
                display: inline-block;
                height: 22px;
                font-size: 14px;
                line-height: 22px;
                padding: 0 6px;
                border-radius: 11px 0 11px 11px;
                color: #fff;
                background: #6c4ecb;
                flex-shrink: 0;
                margin-right: 8px;
              }
              .el-button {
                height: 32px;
                margin-left: auto;
                flex-shrink: 0;
                border-radius: 4px;
                min-width: 64px;
                padding: 0 10px;
              }
            }
            .desc {
              font-size: 14px;
              color: #949495;
              margin-top: 12px;
              line-height: 20px;
            }
          }
        }
        .homework {
          &-item {
            height: 82px;
          }
        }
      }
    }
  }
}

.dialog-container {
  width: calc(100% + 72px);
  margin-left: -36px;
  padding: 30px 0 10px;
  position: relative;

  img {
    width: 100%;
  }

  p {
    width: 100%;
    text-align: center;
    color: #afafaf;
    position: absolute;
    line-height: 2em;
    bottom: 24px;
    left: 0;
  }
}
/* 严选课程列表 */
.strict-course {
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  .course-inner {
    width: 100%;
    height: calc(100% - 72px);
    position: relative;
    box-sizing: border-box;
    padding: 10px 18px;
    .course-list {
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      @include flexBox(space-between);

      .item {
        width: calc((100% - 30px) / 2);
        height: calc((100% - 10px) / 2);
        @include flexBox($align: flex-start);
        box-sizing: border-box;
        padding: 16px 20px 0;
        border-radius: 10px;
        cursor: pointer;
        background: rgba($color: #ede6ff, $alpha: 0.23);
        &:nth-child(n + 3) {
          margin-top: 10px;
        }
        .iconfont-color {
          width: 56px;
          height: 56px;
          font-size: 56px;
          flex-shrink: 0;
          margin-right: 18px;
          @include flexBox(center);
        }
        .about-info {
          width: calc(100% - 66px);
          p {
            @include flexBox;
          }
          .title {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
            font-size: 18px;
            b {
              display: inline-block;
              flex-grow: 1;
              color: #474747;
            }
          }
          .el-tag {
            height: 24px;
            line-height: 24px;
            border-radius: 6px;
            padding: 0 10px;
            margin-right: 8px;
          }
          .teacher,
          .time {
            @include flexBox;
          }
          .teacher {
            font-size: 16px;
            margin: 20px 0 10px;
            color: #474747;
            .el-avatar {
              margin-right: 10px;
            }
          }
          .time {
            font-size: 14px;
            color: #595959;
            .iconfont {
              font-size: 16px;
              margin-right: 4px;
            }
            span {
              margin-right: 32px;
            }
          }
        }
        &:hover {
          background: rgba($color: #ede6ff, $alpha: 0.55);
        }

        //         .title {
        //           height: 56px;
        //           box-sizing: border-box;
        //           padding-left: 56px;
        //           padding-right: 20px;
        //           background: #f6f7fc;
        //           position: relative;
        //           @include flexBox;

        //           &::before {
        //             content: "\e62f";
        //             font-family: "iconfont";
        //             font-size: 28px;
        //             color: rgba($color: #44269a, $alpha: 0.21);
        //             line-height: 56px;
        //             position: absolute;
        //             left: 24px;
        //             top: 0;
        //           }

        //           h4 {
        //             flex-grow: 1;
        //             margin-right: 20px;
        //           }

        //           .el-tag {
        //             flex-shrink: 0;
        //             margin-right: 10px;
        //             border-radius: 6px;

        //             &:last-child {
        //               margin-right: 0;
        //             }
        //           }
        //         }

        //         .about-info {
        //           height: calc(100% - 56px);
        //           box-sizing: border-box;
        //           padding-left: 60px;
        //           padding-right: 18px;
        //           flex-direction: column;
        //           @include flexBox(space-between, flex-start);

        //           li {
        //             width: 100%;
        //             position: relative;
        //             box-sizing: border-box;
        //             color: #595959;
        //             @include flexBox;
        //             &.teacher {
        //               height: 42px;
        //               font-size: 16px;
        //               margin-top: 8px;
        //               .el-avatar {
        //                 margin-right: 8px;
        //               }
        //             }
        //             &:not(.teacher) {
        //               height: 24px;
        //               justify-content: space-between;
        //             }
        //             p {
        //               width: 100%;
        //               @include flexBox;
        //             }
        //             .iconfont {
        //               font-size: 16px;
        //               margin-right: 4px;
        //             }
        //           }
        //         }
      }
    }
  }
}
.list-inner {
  .item {
    width: 100%;
    max-width: 560px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    background: #f6f7fc;
    .title {
      height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
      padding-right: 20px;
      position: relative;
      @include flexBox;
      h4 {
        flex: 1;
      }

      .el-tag {
        flex-shrink: 0;
        margin-right: 10px;
      }
      .type {
        padding: 0 10px;
        height: 20px;
        font-size: 12px;
        background: #f0fff7;
        color: #1dbb62;
        border: 1px solid #1dbb62;
        border-radius: 2px;
        margin-left: 10px;
        &.type1 {
          background: #f0fff7;
          color: #1dbb62;
          border: 1px solid #1dbb62;
        }
        &.type2 {
          background: #f2f4f3;
          color: #888888;
          border: 1px solid #dadada;
        }
        &.type3 {
          background: #f2f4f3;
          color: #aaaaaa;
          border: 1px solid #dadada;
        }
      }
    }
    .content {
      padding: 10px 20px 20px;
    }
    .teacherInfo {
      font-size: 14px;
      .name {
      }
      .date {
        margin-left: 20px;
      }
    }
    .datum {
      margin-top: 16px;
      .assist {
        text-align: center;
        width: 30%;
        color: #ffffff;
        height: 60px;
        padding: 10px 0;
        background-color: #6c4ecb;
        border-radius: 10px;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
      .iconimg {
        margin-bottom: 6px;
        font-size: 30px;
        &.type1 {
          background: linear-gradient(181deg, #ffbd52 0%, #ffab22 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(255, 159, 4, 0.14);
        }
        &.type2 {
          background: linear-gradient(181deg, #66b4f4 0%, #74bdff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(1, 161, 253, 0.14);
        }
        &.type3 {
          background: linear-gradient(181deg, #63d49a 0%, #6ae2a2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          box-shadow: 0rpx 8rpx 6rpx 0rpx rgba(99, 212, 154, 0.2);
        }
      }
    }
    .schedule {
      margin-top: 16px;
      .status {
        width: 30%;
        position: relative;
        padding: 3%;
        box-sizing: border-box;
        cursor: pointer;
        height: 80px;
        .img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
        .text {
          position: relative;
          z-index: 1;
          .h6 {
            color: #fdfdfd;
            font-size: 12px;
            line-height: 20px;
          }
          .h4 {
            color: #fffeff;
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-inner {
    width: calc(100% - 20px);
    margin: 0 0 0 20px;
    .notice-box,
    .container {
      width: calc(100% - 14px);
    }
    .notice-box {
      margin-bottom: 16px;
      height: 64px;
      .el-button {
        height: 36px;
        font-size: 12px;
      }
    }
  }
  .strict-course {
    .course-inner {
      .course-list .item {
        width: calc((100% - 16px) / 2);
        padding: 16px 8px 0;
        .iconfont-color {
          width: 42px;
          height: 42px;
          font-size: 42px;
          margin-right: 10px;
        }
        .about-info {
          width: calc(100% - 52px);
          .el-tag {
            height: 22px;
            line-height: 22px;
            border-radius: 6px;
            padding: 0 6px;
          }
          .title b {
            font-size: 16px;
          }
          .teacher {
            font-size: 14px;
            .el-avatar {
              margin-right: 8px;
            }
          }
          .time {
            font-size: 12px;
            span {
              margin-right: 10px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .home-inner .wrapper-left,
  .home-inner .wrapper-right {
    height: 750px;
  }

  .home-inner .wrapper-left .look-back,
  .home-inner .wrapper-left .strict-course,
  .home-inner .wrapper-right .homework,
  .home-inner .wrapper-right .notice {
    height: calc((100% - 18px) / 2);
  }

  .home-inner .wrapper-left .look-back--inner,
  .strict-course .course-inner,
  .home-inner .wrapper-right .homework--inner,
  .home-inner .wrapper-right .notice--inner {
    height: calc(100% - 48px);
  }

  .home-inner .wrapper-right {
    .notice {
      .box-head {
        padding-left: 36px;
        &::before {
          left: 18px;
        }
        &::after {
          left: 10px;
        }
        .view-all {
          margin-right: 10px;
        }
      }
    }
    .notice--inner {
      padding: 4px 10px;
    }
    .notice-item {
      padding: 10px 8px;
      height: 94px;
      .time .iconfont {
        margin-right: 4px;
      }
      .title .line-text--1st {
        font-size: 14px;
      }
      .desc {
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .list-inner .item .content {
    padding: 20px;
  }
  .list-inner .item .datum {
    margin-top: 24px;
  }
  .list-inner .item .schedule .status {
    height: 64px;
  }
}
.messageicon{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 60px;
  position: fixed;
  right: 20px;
  bottom: 50px;
  box-shadow: 0 0 10px #bdbdbd;
  z-index: 10;
  &:hover{
    opacity: 0.6;
    cursor: pointer;
  }
  .iconfont{
    font-size: 26px;
  }
  .number{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #FF4242;
    color: #FFFFFF;
    min-width: 8px;
    padding: 0 4px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    border-radius: 10px;
  }
}
</style>

<template>
  <section class="home">
    <!-- 已入驻 -->
    <div class="home-inner">
      <div class="messageicon" @click="$router.push({ name: 'HOME_MESSAGE', params: {} })">
          <div class="iconfont">&#xe6a3;</div>
          <div class="number" v-if="userInfo.unread>0">{{userInfo.unread}}</div>
      </div>
      <!-- 滚动消息通知 -->
      <div class="notice-box">
        <i class="iconfont">&#xe651;</i>
        <div class="notice-wrapper swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide notice-item"
              v-for="item in noticeData"
              :key="item.pltea_mes_id"
            >
              <p
                class="line-text--1st"
                @click="
                  $router.push({
                    name: 'HOME_NOTIFYDETAIL',
                    params: { id: item.plstu_mes_id },
                  })
                "
              >
                {{ item.plstu_mes_title }}
              </p>
            </div>
            <div class="swiper-slide notice-item" v-if="$isEmpty(noticeData)">
              <p>暂无通知公告</p>
            </div>
          </div>
        </div>
        <el-button
          type="success"
          @click="$router.push({ name: 'HOME_NOTIFY', params: {} })"
          >查看全部</el-button
        >
      </div>
      <div class="container">
        <div class="wrapper-left">
          <!-- 课程回看 -->
          <div class="look-back">
            <h4 class="box-head">
              <span class="bold">课程回看</span>
              <router-link class="view-all" :to="{ name: 'COURSE_INDEX' }">
                <span>查看全部</span>
                <span class="iconfont">&#xe601;</span>
              </router-link>
            </h4>
            <div
              class="look-back--inner list-inner"
              :class="{ 'no-data': $isEmpty(classschedule) }"
            >
              <el-row :gutter="10" type="flex">
                <el-col
                  :span="12"
                  v-for="(item, index) in classschedule"
                  :key="item.teope_id"
                  v-if="index < 2"
                >
                  <div class="item">
                    <div class="title">
                      <el-tag>{{ item.sysub_name || "-" }}</el-tag>
                      <h4
                        class="bold line-text--1st flex_1"
                        :title="item.sccou_cha_title"
                      >
                        {{ item.sccou_cha_title || "-" }}
                      </h4>
                      <div
                        class="type type2 flex_content_center"
                        v-if="item.is_lessons == 1"
                      >
                        未开始
                      </div>
                      <div
                        class="type type1 flex_content_center"
                        v-if="item.is_lessons == 2"
                      >
                        进行中
                      </div>
                      <div
                        class="type type3 flex_content_center"
                        v-if="item.is_lessons == 3"
                      >
                        已结束
                      </div>
                    </div>
                    <div class="content">
                      <div class="teacherInfo flex_align">
                        <div class="name">授课老师: {{ item.teuse_name }}</div>
                        <div class="date">
                          {{ item.sccla_sch_tim_starttime }}-{{
                            item.sccla_sch_tim_endtime
                          }}
                        </div>
                      </div>
                      <div class="datum flex_bet_align">
                        <div
                          class="assist"
                          @click="
                            $router.push({
                              name: 'SCHOOL_PLAY',
                              params: { id: item.tecla_id },
                            })
                          "
                        >
                          <div class="iconimg type1 iconfont">&#xe6aa;</div>
                          课堂回放
                        </div>
                        <div
                          class="assist"
                          @click="
                            $router.push({
                              name: 'KEY_POINT',
                              params: { id: item.tecla_id },
                            })
                          "
                        >
                          <div class="iconimg type2 iconfont">&#xe6d8;</div>
                          知识点
                        </div>
                        <div class="assist" @click="goChat(item)">
                          <div class="iconimg type3 iconfont">&#xe6d6;</div>
                          联系老师
                        </div>
                      </div>
                      <div class="schedule flex_bet_align">
                        <div
                          class="status"
                          @click="
                            item.practioce_status != 0 && item.is_lessons == 3
                              ? $router.push({
                                  name: 'INTERACTION',
                                  params: { id: item.tecla_id },
                                })
                              : ''
                          "
                        >
                          <img
                            class="img"
                            v-if="item.practioce_status == 0"
                            src="@assets/images/kcbg0.png"
                            alt=""
                          />
                          <img
                            class="img"
                            v-else
                            src="@assets/images/kcbg1.png"
                            alt=""
                          />
                          <div class="text">
                            <div
                              class="h4"
                              :class="item.practioce_status == 0 ? 'c-8' : ''"
                            >
                              {{ item.practioce_status == 0 ? "无" : "课堂" }}
                            </div>
                            <div
                              class="h6"
                              :class="item.practioce_status == 0 ? 'c-8' : ''"
                            >
                              课堂互动
                            </div>
                          </div>
                        </div>
                        <div
                          class="status"
                          @click="
                            item.sthom_school_wor_id != ''
                              ? gojobDetails(item.school_homework_status, item.sthom_school_wor_id, 2)
                              : ''
                          "
                        >
                          <img
                            class="img"
                            v-if="item.sthom_school_wor_id == ''"
                            src="@assets/images/kcbg0.png"
                            alt=""
                          />
                          <img
                            class="img"
                            v-else
                            src="@assets/images/kcbg2.png"
                            alt=""
                          />
                          <div class="text">
                            <div
                              class="h4 c-8"
                              v-if="item.sthom_school_wor_id == ''"
                            >
                              无
                            </div>
                            <div
                              class="h6 c-8"
                              v-if="item.sthom_school_wor_id == ''"
                            >
                              校内作业
                            </div>
                            <div v-else>
                              <div class="h4">已做</div>
                              <div class="h6">校内作业</div>
                            </div>
                          </div>
                        </div>
                        <div class="status" @click="item.homework_status!=0&&item.sthom_wor_id!=''?gojobDetails(item.homework_status,item.sthom_wor_id,1):''">
                                <img class="img" v-if="item.homework_status==0||item.sthom_wor_id==''" src="@assets/images/kcbg0.png" alt="">
                                <img class="img" v-else src="@assets/images/kcbg2.png" alt="">
                                <div class="text">
                                    <div class="h4 c-8" v-if="item.homework_status==0||item.sthom_wor_id==''">无</div>
                                    <div class="h4" v-if="item.homework_status==1&&item.sthom_wor_id!=''">已完成</div>
                                    <div class="h4" v-if="item.homework_status==2&&item.sthom_wor_id!=''">未完成</div>
                                    <div class="h4" v-if="item.homework_status==3&&item.sthom_wor_id!=''">已批阅</div>
                                    <div class="h6" :class="item.homework_status==0||item.sthom_wor_id==''?'c-8':''">家庭作业</div>
                                </div>
                            </div>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="no-data--empty" v-if="$isEmpty(classschedule)">
                <img src="@assets/images/no-data3.png" alt="" />
                <p>暂无课程~</p>
              </div>
            </div>
          </div>
          <!-- 严选课程 -->
          <div class="strict-course">
            <h4 class="box-head">
              <span class="bold">严选课程</span>
              <router-link class="view-all" :to="{ name: 'STRICT_COURSE' }">
                <span>查看全部</span>
                <span class="iconfont">&#xe601;</span>
              </router-link>
            </h4>
            <div
              class="course-inner"
              :class="{ 'no-data': $isEmpty(coursesData) }"
            >
              <ul class="course-list" v-show="!$isEmpty(coursesData)">
                <li
                  class="item"
                  v-for="item in coursesData"
                  :key="item.plstr_cur_id"
                  r
                  @click="
                    $router.push({
                      name: 'STRICT_DETAIL',
                      params: { id: item.plstr_cur_id },
                    })
                  "
                >
                  <i class="iconfont-color" v-html="fileType(item.file_type)" />
                  <div class="about-info">
                    <p class="title">
                      <el-tag size="mini">{{ item.sysub_name || "-" }}</el-tag>
                      <b class="line-text--1st">{{
                        item.plstr_cur_title || "-"
                      }}</b>
                    </p>
                    <p class="teacher">
                      <el-avatar
                        fit="cover"
                        :size="42"
                        :src="formatfile(item.teuse_image)"
                        class="avatar"
                      >
                        <img src="@assets/images/empty_avatar.png" />
                      </el-avatar>
                      <span>{{ item.teuse_name }}</span>
                    </p>
                    <!-- <p class="time">
                      时间：<span>{{ item.plstr_cur_publishtime }}</span
                      >浏览：<span>{{ item.plstr_cur_read }}</span>
                    </p> -->
                    <p class="time">
                      <i class="iconfont">&#xe684;</i
                      ><span>{{ item.plstr_cur_publishtime }}</span
                      ><i class="iconfont">&#xe6c5;</i
                      ><span>{{ item.plstr_cur_read }}</span>
                    </p>
                  </div>
                </li>
              </ul>
              <div class="no-data--empty" v-show="$isEmpty(coursesData)">
                <img src="@assets/images/no-data3.png" alt="" />
                <p>暂无课程~</p>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-right">
          <!-- 作业情况 -->
          <div class="homework">
            <h4 class="box-head">
              <span class="bold">作业情况</span>
              <router-link class="view-all" :to="{ name: 'JOB_INDEX' }">
                <span>查看全部</span>
                <span class="iconfont">&#xe601;</span>
              </router-link>
            </h4>
            <div
              class="homework--inner"
              :class="{ 'no-data': $isEmpty(scheduleData) }"
            >
              <ul class="homework--list" v-if="!$isEmpty(scheduleData)">
                <li
                  class="homework-item"
                  v-for="item in scheduleData"
                  :key="item.testu_exa_id"
                  @click="goCondition(item)"
                >
                  <p class="time">
                    <i class="iconfont">&#xe61a;</i>
                    <span>
                      {{ item.create_time | formatTime("MM月DD日") }}
                      {{ dayOfWeek(item.create_time) }} 丨
                      {{ item.create_time | formatTime("HH:mm") }}
                    </span>
                  </p>
                  <p class="title">
                    <i class="tag">{{ item.sysub_name }}</i>
                    <span class="line-text--1st">{{
                      item.sthom_wor_remark
                    }}</span>
                    <el-button
                      type="primary"
                      v-if="
                        item.sthom_wor_status == 20 &&
                        showAchieve(item.sthom_wor_deadlinetime)
                      "
                    >
                      <span>去完成</span>
                    </el-button>
                    <el-button
                      type="success"
                      v-if="item.sthom_wor_status != 20"
                    >
                      <span>已完成</span>
                    </el-button>
                  </p>
                </li>
              </ul>
              <div class="no-data--empty" v-if="$isEmpty(scheduleData)">
                <img src="@assets/images/no-data3.png" alt="" />
                <p>暂无信息哦~</p>
              </div>
            </div>
          </div>
          <!-- 校园动态 -->
          <div class="notice">
            <h4 class="box-head">
              <span class="bold">校园动态</span>
              <router-link class="view-all" :to="{ name: 'SCHOOLNEWS' }">
                <span>查看全部</span>
                <span class="iconfont">&#xe601;</span>
              </router-link>
            </h4>
            <div
              class="notice--inner"
              :class="{ 'no-data': $isEmpty(schoolNoticeData) }"
            >
              <ul class="notice--list" v-if="!$isEmpty(schoolNoticeData)">
                <li
                  class="notice-item"
                  v-for="item in schoolNoticeData"
                  :key="item.plinf_id"
                  @click="
                    $router.push({
                      name: 'SCHOOLNEWS_DETAIL',
                      params: { id: item.plinf_id },
                    })
                  "
                >
                  <p class="time">
                    <i class="iconfont">&#xe61a;</i>
                    <span>
                      {{ item.create_time | formatTime("MM月DD日") }}
                      {{ dayOfWeek(item.create_time) }}
                    </span>
                  </p>
                  <p class="title">
                    <span class="line-text--1st">{{ item.plinf_title }}</span>
                  </p>
                  <p class="desc line-text--1st">
                    {{ item.plinf_content }}
                  </p>
                </li>
              </ul>
              <div class="no-data--empty" v-if="$isEmpty(schoolNoticeData)">
                <img src="@assets/images/no-data3.png" alt="" />
                <p>暂无信息哦~</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提示弹窗 -->
    <el-dialog
      title="提示"
      width="558px"
      :visible.sync="msgDialog"
      @close="hideDialog"
    >
      <div class="dialog-container">
        <img src="@assets/images/no-data4.png" alt="" />
        <p>
          您暂未激活设备连接服务，为了不影响设备使用，请提醒家长激活该服务。
        </p>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_primary" size="medium" @click="hideDialog">
          我知道了
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import * as home from "@api/home";
import { stuinformationList } from "@api/dynamic";
import { formatTime, formatFile, formatFileType } from "@utils";
import Swiper, { Autoplay, FreeMode, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { mapState } from "vuex";

Swiper.use([Autoplay, FreeMode, Navigation]);

export default {
  name: "home",
  computed: {
    ...mapState("user", ["userInfo"]),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
    dayOfWeek() {
      return function (val) {
        let time = new Date(val).getTime();
        return `星期${formatTime(time, "d")}`;
      };
    },
  },
  watch: {},
  data() {
    return {
      msgDialog: false, // 显示弹窗
      board_exist_txt: "", //弹窗提示的文字
      noticeData: [], // 消息通知数据
      noticeSwiper: null, // 消息通知轮播实例
      coursesData: [], // 严选课程数据
      scheduleData: [], // 作业情况数据
      schoolNoticeData: [], // 校园动态数据
      classschedule: [], //课程信息列表
    };
  },
  async created() {
    // 获取今日课程
    this.getChedule_list();
    // 获取作业情况
    this.getHomework();
    //获取手写板弹窗提示
    this.getRenewal_pop();
    this.getNoticeData();
    this.getCoursesData();
    this.getSchoolNews();
  },
  methods: {
    /** 获取消息通知列表 */
    async getNoticeData() {
      let { data } = await home.get_home_notice();
      this.noticeData = data;
      if (!this.noticeSwiper) {
        this.$nextTick(() => {
          this.initSwiper();
        });
      }
    },
    // 今日课程
    async getChedule_list() {
      // let { data } = await home.classschedule_list({
      //   query_word: "",
      //   startData: "1700064000",
      //   sysub_id: ""
      // });
      let { data } = await home.classschedule_list();
      this.classschedule = data;
    },
    // 跳转聊天
    goChat(item) {
      let userProfile = {
        userID: item.tencent_im_account,
        avatar: item.teuse_image,
        nick: item.teuse_name,
      };
      this.$router.push({
        name: "INTERACTION_MESSAGE",
        params: { userProfile: userProfile },
      });
    },
    // 作业情况
    async getHomework() {
      let { data } = await home.home_homework_list();
      // let { data } = await home.homework_list();
      this.scheduleData = data.data;
    },
    // 手写板弹窗提示
    async getRenewal_pop() {
      let { data } = await home.renewal_pop();
      if (data.board_exist_show == 1) {
        this.msgDialog = true;
        this.board_exist_txt = data.board_text;
      }
      if (data.renewal_is_show == 1) {
        this.msgDialog = true;
        this.board_exist_txt = data.renewal_text;
      }
    },
    /** 获取严选课程列表 */
    async getCoursesData() {
      let params = {
        pagesize: 4,
        plstr_cur_type: 20,
      };
      let { data: res } = await home.plastrictcurriculumList(params);
      res.data.forEach((item) => {
        item.file_type = item.plstr_cur_url.substring(
          item.plstr_cur_url.lastIndexOf(".") + 1
        );
      });
      this.coursesData = res.data;
    },
    async getSchoolNews() {
      let { data: res } = await stuinformationList({ pagesize: 10 });
      this.schoolNoticeData = res.data;
    },

    /** 初始化页面轮播实例 */
    initSwiper() {
      this.noticeSwiper = new Swiper(".notice-wrapper", {
        direction: "vertical",
        speed: 800,
        loop: true,
        noSwiping: true,
        noSwipingClass: "notice-wrapper",
        autoplay: {
          delay: 3000,
        },
      });
      this.$forceUpdate();
    },
    hideDialog() {
      this.msgDialog = false;
    },
    //判断去完成字段是否显示
    showAchieve(date) {
      try {
        return new Date(date.replace(/-/g, "/")) > new Date();
      } catch (e) {
        return false;
      }
    },

    //跳转课堂作业
    gojobDetails(homework_status, sthom_wor_id, type) {
      if(type==2){
          //未批阅
          if(homework_status==2){
            this.$router.push({
                name:"COURSE_DETAILS_NOT",
                params: {
                id:sthom_wor_id,
                type:type
                }
            })
        }else{
            this.$router.push({
                name:"COURSE_DETAILS",
                params: {
                    id:sthom_wor_id,
                    school:0,
                    accomplish:0
                }
            })
        }
      }else{
          //未完成
          if(homework_status==2){
              this.$router.push({
                  name:"COURSE_DETAILS_NOT",
                  params: {
                  id:sthom_wor_id,
                  type:type
                  }
              })
          }else{
              this.$router.push({
                  name:"COURSE_DETAILS",
                  params: {
                  id:sthom_wor_id,
                  school:1,
                  accomplish:1
                  }
              })
          }
      }
    },
    //跳转详情
    goCondition(item) {
      if (item.sthom_wor_status == 20) {
        this.$router.push({
          name: "JOB_DETAILS_NOT",
          params: {
            id: item.sthom_wor_id,
          },
        });
      } else {
        this.$router.push({
          name: "JOB_DETAILS",
          params: {
            id: item.sthom_wor_id,
          },
        });
      }
    },
  },
};
</script>