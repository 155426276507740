import HTTP from "@utils/http";

// 资讯列表
export async function stuinformationList(prams) {
	try {
		return await HTTP.post("/studentpc/stuinformation/list", prams);
	} catch (error) {
		return error;
	}
}
// 资讯详情
export async function stuinformationInfo(prams) {
	try {
		return await HTTP.post("/studentpc/stuinformation/info", prams);
	} catch (error) {
		return error;
	}
}
// 资讯banner
export async function get_banner_list(prams) {
	try {
		return await HTTP.post("/studentpc/stuinformation/get_banner_list", prams);
	} catch (error) {
		return error;
	}
}
// 资讯点赞/取消点赞
export async function add_praise(prams) {
	try {
		return await HTTP.post("/studentpc/stuinformation/add_praise", prams);
	} catch (error) {
		return error;
	}
}